<template>
  <!--  <div style="width: 100%; height: 100%; background-color: #69be28">FRAME VIEW</div>-->
  <div>
    <div ref="fpEditorEx" id="fp-editor-container-ex" style="height: calc(100% - 85px);background-color: coral">
    </div>
  </div>
</template>

<script>
import { initializeFloorplanner, loadScripts } from '@/modules/configurator/util/floorplanner.js';

export default {
  name: "frame.vue",
  data() {
    return {
      fpEditorLoaded: false,
      fpEditor: null,
      settings: {
        useMetric: true,
        templateId: process.env?.VUE_APP_FLOORPLANNER__TEMPLATE_ID,
        user: {
          id: process.env?.VUE_APP_FLOORPLANNER__USER_ID,
          permissions: ['save', 'no-back-to-dashboard', 'favorites', 'ntl'],
          auth_token: process.env?.VUE_APP_FLOORPLANNER__AUTH_TOKEN,
        },
      },
      customStyle: {
        light: '#69be28',
        dark: '#69be28',
      },
    };
  },
  computed: {
    floorplannerId: function (){
      let parameters = this.$route.query
      return parameters.floorplannerid;
    },
    storeId: function(){
      let parameters = this.$route.query;
      return parameters.storeid;
    },
    floorplannerApi: function () {
      return 'floorplanner.com';
    }
  },
  async mounted() {
    window.onmessage = function(e){
      if (e.data.event === 'save-fp-edits'){
        this.save();
      }
    }
    await loadScripts;
    this.raiseEvent("floorplanner-loaded", null);
    await this.$nextTick();
    await this.initializeFloorPlanner();
    this.fpEditor.onUpdated = this.handleFloorplanUpdate;
    this.raiseEvent("fp-editor-loaded", null);
  },
  methods: {
    raiseEvent(event, data){
      window.top.postMessage({"event": event, "data" : data});
    },
    save(){
      this.fpEditor.save();
    },

    handleFloorplanUpdate() {
      this.raiseEvent("floorplanner-updated");
    },
    async initializeFloorPlanner() {
      this.fpEditor = await initializeFloorplanner('#fp-editor-container-ex', {
        apiDomain: this.floorplannerApi,
        projectId: this.floorplannerId,
        ...this.settings,
      });
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .fp___loading__background {
    z-index: 0;
  }

  .fp___loading__logo svg:first-child path:first-child, .fp___loading__back, .fp___loading__spinning, .fp___loading__door, .fp___loading__window, .fp___loading__roof {
    fill: #69be28;
  }

  .fp___loading__back, {
    fill: #2c8800;
  }

  .fp___root {
    // height:           calc(100vh - 85px);
    height:           calc(100vh);

    --light:          rgb(247, 247, 247);
    --sidebar-color:  #69be28;
    --dark:           #69be28;
    --dark-darkened:  #2c8800;
    --dark-lightened: #a3f762;

    .fp___topbar__nav {
      // height: calc(100vh - 85px);
      height: calc(100vh);
    }

    .fp___topbar .fp___floor-dropdown-and-panel-container .fp___new-floor-dropdown-head {
      z-index: 150;
    }
  }
}
</style>